import '../style/App.css'

// ::: image ::: //
import Luna from '../image/luna.webp'
import About_img from '../image/Lune.webp'
import GAYA from '../image/gaya361.webp'
// PDF
import Pdf from "../image/Portfolio.pdf"


    // ::: card img ::: //
    import Mobilis from '../image/mobilis.webp'
    import Rog from '../image/rog.webp'
    import Sim from '../image/sim.webp'
    import Kera from '../image/kera.webp'
    import Kol from '../image/kol.webp'
    import Ry from '../image/rynouz.webp'
    import Mb from '../image/mb.webp'
    import Moon from '../image/moon.webp'
    import Sg from '../image/sg.webp'

    // ::: Video ::: //
    import VKera from '../video/Vgif.webm'
    import VCard from '../video/Vcard.webm'
    import Vsga from '../video/Vsga.webm'

//  ::: Components ::: //
import NavBar from '../Components/NavBar'

// ::: Icons ::: //
import {FaFacebookSquare , FaInstagram ,FaAward , FaChartLine ,FaPrint} from 'react-icons/fa'
import {BiPhoneCall ,BiNetworkChart } from 'react-icons/bi'
import {BsMessenger , BsWhatsapp ,BsFilesAlt  } from 'react-icons/bs'
import {HiOutlineMail , HiOutlineColorSwatch ,HiOutlineCursorClick, HiOutlineCubeTransparent} from 'react-icons/hi'
import {FiHeadphones ,FiDownload} from 'react-icons/fi'
import {RiMailSendFill} from 'react-icons/ri'
import {HiArrowSmRight , HiOutlineUserGroup} from 'react-icons/hi'
import {MdOutlinePhotoSizeSelectLarge , MdWeb ,MdAttachEmail, MdLaptop} from 'react-icons/md'
import {IoCloseSharp } from 'react-icons/io5'
import {VscServerProcess} from 'react-icons/vsc'
import {AiOutlineAppstore} from 'react-icons/ai'




// ::: React ::: //
import { useState , useRef } from 'react'


// Email js //
import emailjs from '@emailjs/browser';



const Home =()=>{

    // :: service Card ::: //
    const [card1 , setCard1] = useState(false)
    const [card2 , setCard2] = useState(false)
    const [card3 , setCard3] = useState(false)

    // Work Popups //
    const [popup , setPopup] = useState(false)
    const [popup2 , setPopup2] = useState(false)
    const [popup3 , setPopup3] = useState(false)
    
    // Work Video Popups //
    const [ppv , setPpv] = useState(false)
    const [ppv1 , setPpv1] = useState(false)
    const [ppv2 , setPpv2] = useState(false)
    

    // ::: Email js ::: //
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_gaya361', 'template_x4h6uod', form.current, 'nI1rHGJbqjVjs2aKJ')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
  
        e.target.reset()
    };
    
    return(
        <>
            <NavBar />

            <main className='main'>

                {/* ::: Home section ::: */}
                <section className='home' id='home'>
                    <div className='home__container container grid'>
                        <div className='home__social'>
                            <span className='home__social-follow'>Follow Us</span>
                            <div className='home__social-link'>
                                <a href='https://www.facebook.com/GAYA361' target="_blank" rel='noreferrer' className='home__social-links facebook'><FaFacebookSquare /></a>
                                <a href='https://www.instagram.com/gaya_361/' target="_blank" rel='noreferrer' className='home__social-links instagram'> <FaInstagram /></a>
                                <a href='tel:+213560845854' target="_blank" rel='noreferrer' className='home__social-links viber'> <BiPhoneCall /></a>
                            </div>
                        </div>

                        <img src={Luna} alt='gaya361' className='home__img' />

                        <div className='home__data'>
                            <h1 className='home__title'>Bienvenue chez GAYA361</h1>
                            <h3 className='home__subtitle'>L'innovation et l'expertise de jeunes entrepreneurs  </h3>
                            <p className='home__description'>Notre équipe s'engage à vous offrir une expérience utilisateur efficace et immersive, ce qui nous permet de capter l'attention et de diffuser un message clair. c'est pourquoi nous accordons une extrême importance aux travail de qualité</p>
                            <a href='#contact' className='button'> <RiMailSendFill className='button__icon' /> Contactez Nous</a>
                        </div> 

                        <div className='my__info'>
                            <div className='info__item'>
                                <BsMessenger className='info__icon messenger'/>

                                <a href='https://www.facebook.com/GAYA361' target="_blank" rel='noreferrer'>
                                    <h3 className='info__title'>Messenger</h3>
                                    <span className='info__subtitle'>Gaya361</span>
                                </a>
                            </div>

                            <div className='info__item'>
                                <BsWhatsapp className='info__icon whatsapp'/>

                                <a href='tel:+213560845854'>
                                    <h3 className='info__title'>Whatsapp</h3>
                                    <span className='info__subtitle'>+213 560 845 854</span>
                                </a>
                            </div>

                            <div className='info__item'>
                                <HiOutlineMail className='info__icon gmail'/>

                                <a href='mailto:contact@gaya361.com'>
                                    <h3 className='info__title'>Email</h3>
                                    <span className='info__subtitle'>contact@gaya361.com</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                {/* ::: About Section ::: */}
                <section className='about section' id='about'>
                    <h2 data-heading="Intro" className='section__title'>À Propos</h2>

                    <div className='about__container container grid'>
                        <img src={About_img} alt='gaya361' className='about__img' />

                        <div className='about__data'>
                            <h3 className='about__heading'>UNE SOLUTION GLOBALE, UN SUPPORT SUR MESURE OU UN SIMPLE CONSEIL?</h3>
                            <p className='about__descripiton'>Notre groupe de communication apporte des solutions à tous les types d’entreprises ou institutions, grâce à notre large champ d’action et nos partenaires, nous pouvons assurer l’intégralité de vos projets, de leurs conceptions à leurs réalisations : création d’identité visuelle, création graphique et édition, impression de supports publicitaires, rédaction de contenu, création de site Internet, gestion d’e-réputation, montages vidéo, conseils en stratégie de communication & publicité, logiciel et développement.</p>

                            <div className='about__info'>

                                <div className='about__box'>
                                    <FaAward className='about__icon'/>
                                    <h3 className='about__title'>Expérience</h3>
                                    <span className='about__subtitle'> + 7ans</span>
                                </div>

                                <div className='about__box'>
                                    <BsFilesAlt className='about__icon'/>
                                    <h3 className='about__title'>Projets</h3>
                                    <span className='about__subtitle'> +150 </span>
                                </div>

                                <div className='about__box'>
                                    <FiHeadphones className='about__icon'/>
                                    <h3 className='about__title'>Support</h3>
                                    <span className='about__subtitle'> en line</span>
                                </div>
                                
                            </div>

                            <a href={Pdf} download className='button'><FiDownload />Télécharger le portfolio</a>
                        </div>
                    </div>
                </section>

                {/* ::: Service Section ::: */}
                <section className='service section' id='services'>
                    <h2 data-heading='Services' className='section__title'>Ce que nous offrons</h2>

                    <div className='services__container container grid'>
                        
                        <div className='services__content'>
                            <div>
                                <MdLaptop className='services__icon'/>
                                <h3 className='services__title'>Dev <br />Informatique</h3>
                            </div>

                            <span className='services__button' onClick={()=>{
                                setCard1(true)
                            }}>
                                Voir plus <HiArrowSmRight className='services__button-icon'/>
                            </span>

                            <div className={`services__model ${card1 && 'show__model'}`}>

                                <div className='services__model-content'>
                                    <IoCloseSharp className='services__model-close' onClick={()=>{
                                        setCard1(false)
                                    }}/>

                                    <h3 className='services__model-title'>Dev Informatique</h3>
                                    <p className='services__model-description'>Nous vous proposons des solutions informatique, clés en mains, adaptables à vos besoins et votre secteur d’activité.</p>

                                    <ul className='services__model-services grid'>

                                        <li className='services__model-service'>
                                            <HiOutlineCursorClick className='services__model-icon'/>
                                            <p className='services__model-info'>Creation de sites Internet</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <AiOutlineAppstore className='services__model-icon'/>
                                            <p className='services__model-info'> Développement d'applications et logiciels</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <VscServerProcess className='services__model-icon'/>
                                            <p className='services__model-info'> Solutions CRM </p>
                                        </li>

                                    </ul>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='services__content'>
                            <div>
                                <HiOutlineColorSwatch className='services__icon'/>
                                <h3 className='services__title'> Marketing <br />Digital</h3>
                            </div>

                            <span className='services__button' onClick={()=>{
                                setCard2(true)
                            }}>
                                Voir plus <HiArrowSmRight className='services__button-icon'/>
                            </span>

                            <div className={`services__model ${card2 && 'show__model'}`}>

                                <div className='services__model-content'>
                                    <IoCloseSharp className='services__model-close' onClick={()=>{
                                        setCard2(false)
                                    }}/>

                                    <h3 className='services__model-title'>Marketing Digital</h3>
                                    <p className='services__model-description'>Nous créons des expériences uniques et rentables <br /> afin d'aider les entreprises à améliorer leur relation client au travers des dispositifs ingénieux, pour couvrir les enjeux d’acquisition et de rétention des utilisateurs.</p>

                                    <ul className='services__model-services grid'>

                                        <li className='services__model-service'>
                                            <BiNetworkChart className='services__model-icon'/>
                                            <p className='services__model-info'>Gestion des réseaux sociaux</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <HiOutlineUserGroup className='services__model-icon'/>
                                            <p className='services__model-info'> Acquisition clients</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <FaChartLine className='services__model-icon'/>
                                            <p className='services__model-info'> Web & social marketing</p>
                                        </li>

                                    </ul>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='services__content'>
                            <div>
                                <MdOutlinePhotoSizeSelectLarge className='services__icon'/>
                                <h3 className='services__title'>Infographie <br />& Design</h3>
                            </div>

                            <span className='services__button' onClick={()=>{
                                setCard3(true)
                            }}>
                                Voir plus <HiArrowSmRight className='services__button-icon'/>
                            </span>

                            <div className={`services__model ${card3 && 'show__model'}`}>

                                <div className='services__model-content'>
                                    <IoCloseSharp className='services__model-close' onClick={()=>{
                                        setCard3(false)
                                    }}/>

                                    <h3 className='services__model-title'>UX/Ui design</h3>
                                    <p className='services__model-description'>Une agence indépendante, avec comme idée maîtresse la souplesse et l'expertise. Nous réalisons des solutions étudiées au cas par cas pour chacun de nos clients.</p>

                                    <ul className='services__model-services grid'>

                                        <li className='services__model-service'>
                                            <MdWeb className='services__model-icon'/>
                                            <p className='services__model-info'> Creation d'identité visuelle</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <HiOutlineCubeTransparent className='services__model-icon'/>
                                            <p className='services__model-info'> Modelisation 3D</p>
                                        </li>

                                        <li className='services__model-service'>
                                            <FaPrint className='services__model-icon'/>
                                            <p className='services__model-info'> Impression & Signalétique</p>
                                        </li>

                                    </ul>
                                    
                                </div>
                            </div>
                        </div>

                    </div>                    
                    
                </section>

                {/* ::: Work Section ::: */}
                <section className='work section' id='work'>
                    <h2 data-heading='Portfolio' className='section__title'>Travaux Récents</h2>


                    <div className='work__container container grid'>

                        <div className='work__card'>
                            <img src={Kol} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>KOLeader</h3>
                            <a  href='https://koleader.academy/' target="_blank" rel="noreferrer" className='work__button'>Site web <HiArrowSmRight className='work__button-icon'/></a>

                        </div>

                        <div className='work__card'>
                            <img src={Moon} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>MOON - Mon Envie</h3>
                            <a  href='https://moonmonenvie.dz/' target="_blank" rel="noreferrer" className='work__button'>Site web <HiArrowSmRight className='work__button-icon'/></a>
                        </div>

                        <div className='work__card'>
                            <img src={Mb} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>M.Bennamani</h3>
                            <a  href='https://mbennamani.com/' target="_blank" rel="noreferrer" className='work__button'>Site web <HiArrowSmRight className='work__button-icon'/></a>
                        </div>
                        
                        <div className='work__card'>
                            <img src={Kera} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>KERA QUEEN'S</h3>
                            <span className='work__button' onClick={()=>{
                                setPpv(true)
                            }}>Video <HiArrowSmRight className='work__button-icon'/></span>

                        </div>

                        <div className='work__card'>
                            <img src={Ry} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>Rynouz</h3>
                            <span className='work__button' onClick={()=>{
                                setPpv1(true)
                            }}>Video <HiArrowSmRight className='work__button-icon'/></span>

                        </div>

                        <div className='work__card'>
                            <img src={Sg} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>SOCIETE GENERAL</h3>
                            <span className='work__button' onClick={()=>{
                                setPpv2(true)
                            }}>Video <HiArrowSmRight className='work__button-icon'/></span>

                        </div>

                        <div className='work__card'>
                            <img src={Mobilis} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>Mobilis</h3>
                            <span className='work__button' onClick={()=>{
                                setPopup(true)
                            }}>Détails <HiArrowSmRight className='work__button-icon'/></span>

                        </div>

                        <div className='work__card'>
                            <img src={Rog} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>Asus Algerie</h3>
                            <span className='work__button' onClick={()=>{
                                setPopup2(true)
                            }}>Détails <HiArrowSmRight className='work__button-icon'/></span>

  
                        </div>

                        <div className='work__card'>
                            <img src={Sim} className='work__img' alt='gaya361' />
                            <h3 className='work__title'>Sim</h3>
                            <span className='work__button' onClick={()=>{
                                setPopup3(true)
                            }}>Détails <HiArrowSmRight className='work__button-icon'/></span>
                        </div>
                    </div>

     

                    {/* ::: Popup ::: */}

                    {/* mobilis */}
                    <div className={`portfolio__popup ${popup && 'show__popup'}`}>
                        <div className='portfolio__popup-inner'>
                            <div className='portfolio__popup-content grid'>

                                <IoCloseSharp className='protfolio__popup-close' onClick={()=>{
                                    setPopup(false)
                                }}/>
                                <div className='pp__thumbnail'>
                                    <img src={Mobilis} className='pp__img' alt='gaya361' />
                                </div>

                                <div className='portfolio__popup-info'>
                                    
                                    <div className='portfolio__popup-body'>
                                        <div className='portfolio__item-details'>
                                            <h3 className='details__title'>Mobilis</h3>
                                            <p className='details__description'>Réalisation des concepts pour mobilis depuis 2014, des campagnes 360, du master visuel aux adaptations en tout genre, affiches, affichage urbain, insertion press, PLV ftyers, bâches, autocollants ...</p>

                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    {/* Asus Algerie */}
                    <div className={`portfolio__popup ${popup2 && 'show__popup'}`}>
                        <div className='portfolio__popup-inner'>
                            <div className='portfolio__popup-content grid'>

                                <IoCloseSharp className='protfolio__popup-close' onClick={()=>{
                                    setPopup2(false)
                                }}/>
                                <div className='pp__thumbnail'>
                                    <img src={Rog} className='pp__img' alt='gaya361' />
                                </div>

                                <div className='portfolio__popup-info'>
                                    <div className='portfolio__popup-body'>
                                        <div className='portfolio__item-details'>
                                            <h3 className='details__title'>Asus Algerie</h3>
                                            <p className='details__description'>Réalisation de divers conceptions graphiques (affichage urbain et digitale) et une campagne 360 aboutissant à un evenement pour célébrer les 30 ans d'ASUS</p>

                                          

                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    {/* Sim */}
                    <div className={`portfolio__popup ${popup3 && 'show__popup'}`}>
                        <div className='portfolio__popup-inner'>
                            <div className='portfolio__popup-content grid'>

                                <IoCloseSharp className='protfolio__popup-close' onClick={()=>{
                                    setPopup3(false)
                                }}/>
                                <div className='pp__thumbnail'>
                                    <img src={Sim} className='pp__img' alt='gaya361' />
                                </div>

                                <div className='portfolio__popup-info'>
                                    <div className='portfolio__popup-body'>
                                        <div className='portfolio__item-details'>
                                            <h3 className='details__title'>Sim</h3>
                                            <p className='details__description'>Group sim conception, realisation et production nous avons accompagner le group dans divers domaines tel que: cadeau de fin d'année, etiquette, packaging, site web et animaiton motion. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 


                    {/* Video Popup */}
                    <div className={`video__popup ${ppv && 'show__ppv'}`}>
                        <div className='video__popup-inner'>
                            <div className='video__popup-content grid'>

                                <IoCloseSharp className='video__popup-close' onClick={()=>{
                                    setPpv(false)
                                }}/>

                                <video controls src={VKera} type='mp4' className='video'/>
                                
                            </div>
                        </div>
                    </div>

                    <div className={`video__popup ${ppv1 && 'show__ppv'}`}>
                        <div className='video__popup-inner'>
                            <div className='video__popup-content grid'>

                                <IoCloseSharp className='video__popup-close' onClick={()=>{
                                    setPpv1(false)
                                }}/>

                                <video controls src={VCard} type='mp4' className='video'/>
                                
                            </div>
                        </div>
                    </div>

                    <div className={`video__popup ${ppv2 && 'show__ppv'}`}>
                        <div className='video__popup-inner'>
                            <div className='video__popup-content grid'>

                                <IoCloseSharp className='video__popup-close' onClick={()=>{
                                    setPpv2(false)
                                }}/>

                                <video controls  src={Vsga} type='mp4' className='video'/>
                                
                            </div>
                        </div>
                    </div>
                    
                    
                </section>

                {/* ::: Contact section ::: */}
                <section className='contact section' id='contact'>
                    <h2 data-heading="Get in touch" className='section__title'>Contactez-nous</h2>

                    <div className='contact__container container grid'>
                        {/* left */}
                        <div className='contact__content'>
                            <div className='contact__info'>

                                <a className='contact__card' href='mailto:contact@gaya361.com' target="_blank" rel='noreferrer'>
                                    <MdAttachEmail className='contact__card-icon '/>
                                    <h3 className='contact__card-title'>Email</h3>
                                    <span className='contact__card-data'>contact@gaya361.com</span>

                                    <span className='contact__button'>
                                        <a href='mailto:contact@gaya361.com' className='contact__icon-iconn' target="_blank" rel='noreferrer'>
                                            Ecrire <HiArrowSmRight className='contact__button-icon '/>
                                        </a>
                                    </span>
                                </a>
                                
                                <a href='tel:+213560845854' className='contact__card' target="_blank" rel='noreferrer'>
                                    <BsWhatsapp className='contact__card-icon '/>
                                    <h3 className='contact__card-title'>Whatsapp</h3>
                                    <span className='contact__card-data'>+213 560 845 854</span>

                                    <span className='contact__button'>
                                        <a href='tel+213560845854' className='contact__icon-iconn'  target="_blank" rel='noreferrer'>
                                        Appeler <HiArrowSmRight className='contact__button-icon'/>
                                        </a>
                                    </span>
                                </a>

                                <a href='https://www.facebook.com/GAYA361' className='contact__card' target="_blank" rel='noreferrer'>
                                    <BsMessenger className='contact__card-icon '/>
                                    <h3 className='contact__card-title'>Messenger</h3>
                                    <span className='contact__card-data'>Gaya361</span>

                                    <span className='contact__button'>
                                        <a href='https://www.facebook.com/GAYA361' className='contact__icon-iconn'  target="_blank" rel='noreferrer'>
                                            Ecrire <HiArrowSmRight className='contact__button-icon'/>
                                        </a>
                                    </span>
                                </a>
                            </div>
                        </div>
                        {/* right */}
                        <div className='contact__content'>
                            <form ref={form} onSubmit={sendEmail} className='contact__form'>
                                <div className='input__container'>
                                    <input type="text" name="name" className='input' placeholder='Nom Complet/Raison Sociale' required/>
                                </div>

                                <div className='input__container'>
                                    <input type="email" name="email"  className='input' placeholder='Email' required/>
                                </div>

                                <div className='input__container'>
                                    <input type="tel" name="phone"  className='input' placeholder='Numéro de téléphone' required/>
                                </div>

                                <div className='input__container'>
                                    <textarea name="message" cols='0' rows='7' className='input' placeholder='Message' />
                                </div>

                                <input type="submit" value="Envoyer" className='button buttons' onClick={()=>{
                                    alert('Votre message a bien été envoyé, Nous reviendrons vers vous au plus vite !')
                                }}/>
                            </form>
                        </div>
                    </div>
                </section>
                 
                 {/* ::: Footer ::: */}
                 <section className='footer section' id='footer'>
                     <div className='footer__bg'>
                         <div className='footer__container container grid'>
                             <div className='footer__i'>
                                 <img src={GAYA} alt='gaya361' className='footer__img' />
                             </div>

                             <ul className='footer__links'>
                                <li>
                                    <a href='#services' className='footer__link'>Services</a>
                                </li>
                                
                                <li>
                                    <a href='#work' className='footer__link'>Work</a>
                                </li>

                                <li>
                                    <a href='#contact' className='footer__link'>Contact</a>
                                </li>
                             </ul>

                             <div className='footer__socials'>
                                <a href='https://www.facebook.com/GAYA361' target="_blank" rel='noreferrer' className='footer__social'>
                                    <FaFacebookSquare className='facebook'/>
                                </a>

                                <a href='https://www.instagram.com/gaya_361/' target="_blank" rel='noreferrer' className='footer__social'> 
                                    <FaInstagram className='instagram'/>
                                </a>
                                
                                <a href='tel:+213560845854' target="_blank" rel='noreferrer' className='footer__social'>
                                    <BiPhoneCall className='viber'/>
                                </a>
                            
                             </div>

                             <p className='footer__copy'>&#169; De la Com 360 avec 1+</p>
                         </div>
                     </div>
                 </section>
                
            </main>
        </>
    )
}

export default Home