// ::: Components ::: //
import Home from "./Page/Home";

// ::: React Hooks ::: //
import { useState , useEffect } from "react";
import './style/Loading.css'
import LoadingSvg from './video/GAYA361_animated.svg'

function App() {

  const [loading , setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4500);
  },[])
  
  
  return (
    <div className="App">

      {
      loading ?
      
      <div className="Loading">
      <img src={LoadingSvg} alt='gaya' className='Loading__img' loop/>
      </div>

      :

      <Home />
    }
    </div>
  );
}

export default App;
